<template>
    <div class="drafts-main">
        <el-table :data="classifiesTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#ECEBFF',}"
                  :cell-style="{fontSize: '14px',color: '#333'}"
                  ref="multipleTable"
                  tooltip-effect="dark"
                  @selection-change="handleSelectionChange">
            <el-table-column
                    type="selection"
                    width="55">
            </el-table-column>
            <el-table-column prop="id" label="序号" align="left"></el-table-column>
            <el-table-column prop="name" label="脚本名称" align="center"></el-table-column>
            <el-table-column prop="create" label="创建时间"  sortable align="center"></el-table-column>
            <el-table-column prop="cover_url" label="封面" align="center">
                <template slot-scope="scope">
                    <div class="coverImg">
                        <img :src="scope.row.cover_url?scope.row.cover_url:require('../../assets/img/image/moren.jpg')">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="classification_name" label="分类" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="small" type="text" class="btn-green-script" @click="viewScriptData(scope.row)">查看</el-button>
                    <el-button size="small" type="text" class="btn-blue-script" @click="editScriptData(scope.row)">编辑</el-button>
                    <el-button size="small" type="text" class="btn-red-script" @click="dellData(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" style="padding-bottom: 10px"
                :current-page="classifiesPages.currentPageNum"
                :page-size="classifiesPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="classifiesPages.total"
                @current-change="classifiesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "draftsStudentModule",
        data(){
            return {
                searchInput: '',
                classifiesTable: [],
                self:'',
                status: '',
                multipleSelectionId: [],
                // selectScript:[],
                //分页
                classifiesPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getDraftsScriptList();
            // this.getCurrentList();
        },
        methods: {
            handleSelectionChange(val) {
                this.multipleSelectionId = [];
                for (let i in val) {
                    this.multipleSelectionId.push(val[i].id)
                }
                console.log(this.multipleSelectionId)

            },
            // 获取脚本
            getDraftsScriptList(searchInput) {
                let param = {
                    page: this.classifiesPages.currentPageNum,
                    pageSize: this.classifiesPages.eachPageNum,
                    paging: 1,
                    status: 2,
                }
                if (searchInput !== '') {
                    param.name = searchInput
                }
                this.$httpStudent.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.classifiesTable = res.data.data
                            // for (let i in res.data.data){
                            //     this.classifiesTable[i]['CurrentName'] = this.showCurrentName(res.data.data[i].classification_id)
                            // }
                            this.classifiesPages.total = res.data.total
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            },
            //分页
            classifiesCurrentChange(val) {
                this.classifiesPages.currentPageNum = val;
                this.getScriptList()
            },
            // // 获取脚本分类
            // getCurrentList() {
            //     this.$httpStudent.axiosGet(this.$api.getClassification, (res) => {
            //         if (res.code === 200) {
            //             this.selectScript = res.data.data;
            //         }
            //     }, (err) => {
            //         console.log(err)
            //     })
            // },
            // //获取分类名称
            // showCurrentName(id){
            //     for (let i in this.selectScript){
            //         if (this.selectScript[i].id === id){
            //             console.log(this.selectScript[i].name)
            //             return this.selectScript[i].name;
            //         }
            //     }
            // },
            // 编辑
            editScriptData(item){
                this.$emit("setContentTab", 1);
                this.$emit("editScript",item);
            },
            //查看
            viewScriptData(item){
                this.$router.push({
                    path: '/student/script/view/creation',
                    query: {
                        id: item.id
                    }
                });
            },
            dellData(id) {
                //    删除数据
                let idArr = [];
                if (id) {
                    //    删除指定 id
                    idArr.push(id)
                } else {
                    if (this.multipleSelectionId.length===0){
                        this.$message({
                            type: "error",
                            message: '清选择脚本',
                            duration: 1000
                        });
                        return;
                    }
                    idArr = this.multipleSelectionId;
                }
                let param = {
                    id: JSON.stringify(idArr)
                }
                this.$confirm('是否删除该脚本，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'blue-btn',
                    type: 'warning',
                }).then(() => {
                this.$httpStudent.axiosPost(this.$api.delScript, param, res => {
                        if (res.code === 200) {
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                            });
                            this.getDraftsScriptList();
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .drafts-main {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 340px);
        .coverImg{
            width:99px;
            height: 57px;
            display: inline-block;
            img{
                width:99px;
                height: 57px;
            }
        }
    }
</style>