export const randomString = (length) => {
	let ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	ALPHABET += 'abcdefghijklmnopqrstuvwxyz';
	ALPHABET += '0123456789';
	let str = '';
	for (let i = 0; i < length; i++) {
		let rand = Math.floor(Math.random() * ALPHABET.length);
		str += ALPHABET.substring(rand, rand+1);
	}
	return str;
}
