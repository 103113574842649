<template>
    <div class="script-creation-main">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
            <el-breadcrumb-item class="first">创作中心</el-breadcrumb-item>
            <el-breadcrumb-item class="second">脚本创作</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="script-wrapper">
            <div class="script-creation-tabs">
                <div class="script-tabs" :class="{current: contentTabCurrent === 0}" @click="toggleScriptTab(0)">脚本管理</div>
                <div class="script-tabs" :class="{current: contentTabCurrent === 1}" @click="toggleScriptTab(1)">新增脚本</div>
            </div>
            <div class="script-item" :class="{current: contentTabCurrent === 0}">
                <div class="script-content-list">
                    <el-tabs v-model="scriptTab" @tab-click="changeScriptTab" class="script-content-tab">
                        <div class="content">
                            <div class="item">
                                <el-button type="text" class="btn-red-script" icon="el-icon-delete" @click="toggleSelection()">删除</el-button>
                            </div>
                            <div class="script-search">
                                <el-input v-model="searchInput" size="small" @keyup.enter.native="searchScriptCaption" placeholder="请输入搜索内容">
                                    <i class="el-icon-search el-input__icon search-btn" slot="suffix"
                                       @click="searchScriptCaption"></i>
                                </el-input>
                            </div>
                        </div>
                        <el-tab-pane label="脚本库" name="script"> </el-tab-pane>
                        <el-tab-pane label="草稿箱" name="drafts"></el-tab-pane>
                        <ScripStudentModule @editScript="editScriptFun" @setContentTab="setContentTabCurrent" ref="ScripStudentModule" v-if="scriptTab === 'script'"/>
                        <DraftsStudentModule @editScript="editScriptFun" @setContentTab="setContentTabCurrent" ref="DraftsStudentModule" v-else />
                    </el-tabs>
                </div>
            </div>
            <div class="script-item " :class="{current: contentTabCurrent === 1}">
                <div class="script-item-left">
                    <div class="script-content">
                        <div class="script-model">脚本模板</div>
                        <div class="script-search">
                            <el-input v-model="scriptSearch" size="small" placeholder="请输入搜索内容">
                                <i class="el-icon-search el-input__icon search-btn" slot="suffix"
                                   @click="searchScriptHot"></i>
                            </el-input>
                        </div>
                        <div class="hot-script">热门脚本</div>
                    </div>
                    <el-scrollbar class="script-list" :native="false">
                        <div class="item" v-for="(scriptItem, scriptIndex) in scriptList"
                             :key="`scriptList${scriptIndex}`">
                            <div class="item-top">
                                <div class="script-img"><img :src="scriptItem.cover_url" alt=""></div>
                                <div  class="script text-overflow-3">{{scriptItem.name}}</div>
                            </div>
                            <div class="script-btn">
                                <el-button class="blue-btn" size="small"  @click="useCreate(scriptItem,scriptIndex)">
                                    一键使用
                                </el-button>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="script-item-right">
                    <el-scrollbar class="script-list01" :native="false">
                        <ScriptCreationModule @setContentTab="setContentTabCurrent" @scriptTab="setScriptTab" ref="ScriptCreationModule"/>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ScriptCreationModule from '../../../components/creativecentermodule/ScriptCreationModule.vue'
    import ScripStudentModule from '../../../components/creativecentermodule/ScripStudentModule.vue'
    import DraftsStudentModule from '../../../components/creativecentermodule/DraftsStudentModule.vue'

    export default {
        name: "ScriptCreation",
        components: {
            ScriptCreationModule,
            ScripStudentModule,
            DraftsStudentModule,
        },
        data() {
            return {
                contentTabCurrent: 0,
                createTitle: '',
                scriptSearch: '',
                scriptTab: 'script',
                scriptList: [],
                scriptItem: {},  //一键使用的脚本对象
                editorW: null,
                searchInput:''
            }
        },
        methods: {
            // 获取脚本列表
            getScriptListAdmin() {
                let param = {
                    status: 1,
                    self:0,
                }
                if(this.scriptSearch !==''){
                    param.name = this.scriptSearch
                }
                this.$httpStudent.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.scriptList = res.data.data
                            // for (let i in this.scriptList){
                            //     this.scriptList[i]['status'] = false;
                            // }
                            // this.classifiesTable = res.data.data
                            // for (let i in res.data.data){
                            //     this.classifiesTable[i]['CurrentName'] = this.showCurrentName(res.data.data[i].classification_id)
                            // }
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            },
            // 标签切换
            toggleScriptTab(index) {
                this.contentTabCurrent = index
                let obj = {
                    name: '',
                    cover_file: '',
                    classification_id: '',
                    script_introduction: '',
                    camera_lens: []
                }
                this.$refs.ScriptCreationModule.id = null;
                this.$refs.ScriptCreationModule.addScriptForm = obj;
                this.$refs.ScriptCreationModule.setWEHtml('')
                if(this.contentTabCurrent === 1){
                    this.getScriptListAdmin()
                }
            },
            // 一键使用
            useCreate(item) {
                // this.scriptList[index].status = !this.scriptList[index].status;
                let obj = {
                    name: item.name,
                    cover_file: item.cover_url,
                    classification_id: item.classification_id,
                    script_introduction: item.script_introduction,
                    camera_lens: JSON.parse(item.camera_lens) ||[]
                }
                this.$refs.ScriptCreationModule.addScriptForm = obj;
                this.$refs.ScriptCreationModule.setWEHtml(item.script_content)
                this.$message.success('一键使用成功')
            },
            //热门脚本搜索
            searchScriptHot(){
                this.getScriptListAdmin()
            },
            //搜索
            searchScriptCaption() {
                if(this.scriptTab === 'script'){
                    this.$refs.ScripStudentModule.getScriptList(this.searchInput);
                }else {
                    this.$refs.DraftsStudentModule.getDraftsScriptList(this.searchInput);
                }
            },
            //切换tab
            changeScriptTab() {
                this.searchInput = '';
                if(this.scriptTab === 'script'){
                    if (this.$refs.ScripStudentModule){
                        this.$refs.ScripStudentModule.getScriptList();
                    }
                }else {
                    if (this.$refs.DraftsStudentModule){
                        this.$refs.DraftsStudentModule.getDraftsScriptList();
                    }

                }
            },
            toggleSelection() {
                if(this.scriptTab === 'script'){
                    this.$refs.ScripStudentModule.dellData();
                }else {
                    this.$refs.DraftsStudentModule.dellData();
                }

            },
            setContentTabCurrent(i){
                this.contentTabCurrent  =i;
                if(this.scriptTab === 'script'){
                    this.$refs.ScripStudentModule.getScriptList();
                }else {
                    this.$refs.DraftsStudentModule.getDraftsScriptList();

                }
            },
            setScriptTab(type){
                this.scriptTab = type;
            },
            // 脚本编辑
            editScriptFun(item) {
                this.getScriptListAdmin();
                let param = {
                    status: 2,
                    id: item.id,
                };
                this.$httpStudent.axiosGetBy(this.$api.getScript, param, (res) => {
                        if (res.code === 200) {
                            let obj = {
                                name: res.data.name,
                                cover_file: res.data.cover_url,
                                classification_id:res.data.classification_id > 0 ? res.data.classification_id:'',
                                script_introduction: res.data.script_introduction,
                                camera_lens: res.data.camera_lens,
                            };
                            this.$refs.ScriptCreationModule.id = res.data.id;
                            this.$refs.ScriptCreationModule.addScriptForm = obj;
                            this.$refs.ScriptCreationModule.setWEHtml(res.data.script_content);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000,
                            });
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            },
        }
    }
</script>

<style scoped lang="scss">
    .script-creation-main {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
        .script-wrapper {
            display: flex;
            height: 1%;
            flex: 1;
            flex-direction: column;
            background: #fff;
            border-radius: 10px;
            padding: 30px 40px;
            .script-creation-tabs {
                .script-tabs {
                    display: inline-block;
                    margin-right: 20px;
                    position: relative;
                    cursor: pointer;
                    transition: all .3s;
                    background-color: #F1F0FE;
                    color: #574DED;
                    border-radius: 4px;
                    padding: 4px 16px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.current {
                        background-color: #574DED;
                        color: #fff;
                    }

                    &:hover {
                        background-color: #1E33E4;
                        color: #fff;
                    }
                }
            }

            .script-item {
                display: none;
                height: 1%;
                flex: 1;
                justify-content: flex-start;
                .script-item-left {
                    width: 35%;
                    display: flex;
                    flex-direction: column;
                    padding-right: 20px;

                    .script-content {
                        border-bottom: 1px solid #EAEAEA;
                        .script-model {
                            text-align: center;
                            padding:20px 0;
                        }
                        .hot-script {
                            text-align: left;
                            padding:20px;
                        }
                    }
                    .script-list {
                        height: 1%;
                        flex: 1;
                        margin: 10px 0;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }

                        .item {
                            padding: 16px;
                            border-bottom: 1px solid #EAEAEA;

                            .script {
                                margin-bottom: 10px;
                                color: #333;
                            }
                            .script-btn {
                                text-align: right;
                                .script-btn-blue {
                                    border-radius: 4px;
                                    background: #574DED;
                                    color: #fff;
                                    &:hover {
                                        background: #1E33E3;
                                    }
                                }
                                ::v-deep.el-button--danger.is-plain{
                                    color: #574DED;
                                    background: #F1F0FE;
                                    border-color: #F1F0FE;
                                }
                                ::v-deep.el-button--danger.is-plain:focus, ::v-deep.el-button--danger.is-plain:hover {
                                    background: #574DED;
                                    border-color: #574DED;
                                    color: #FFF;
                                }
                            }

                            &:last-child {
                                border-bottom: none;
                            }

                            .item-top {
                                display: flex;

                                .script-img {
                                    width: 93px;
                                    height: 58px;
                                    display: inline-block;
                                    margin-right: 10px;
                                    img{
                                        width: 93px;
                                        height: 58px;
                                    }
                                }
                            }
                        }
                    }
                }

                .script-item-right {
                    display: flex;
                    flex-direction: column;
                    padding-left: 20px;
                    border-left: 1px solid #eee;

                    .script-list01 {
                        flex: 1;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                    }

                }

                &.current {
                    display: flex;
                }

                .script-item {
                    flex: 1;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }

                .script-content-list {
                    width: 100%;
                    padding:30px 0;
                    box-sizing: border-box;
                    .script-content-tab {
                        ::v-deep .el-tabs__item {
                            height: 45px;
                            line-height: 45px;
                            font-size: 16px;
                            font-weight: 400;
                            border: none;
                            color: #16113F;
                        }

                        ::v-deep .el-tabs__active-bar {
                            background: #9ACC5B;
                        }

                        ::v-deep .el-tabs__active-bar:before {
                            position: absolute;
                            top: -13px;
                            left: 40%;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: dashed dashed solid dashed;
                            border-width: 7px;
                            border-color: transparent transparent #9ACC5B transparent;
                        }
                    }
                }
            }
        }
        .content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            .item{
                .btn-red-script{
                    display: inline-block;
                    color: #F90B32 ;
                    font-size: 14px;
                }
                .btn-red-script:hover,
                .btn-red-script:focus,
                .btn-red-script.current {
                    color: #dc0012;
                }
            }
        }
        .script-search {
            display: flex;
            .el-input {
                width: 1%;
                flex: 1;
                margin-left: 10px;
            }
            ::v-deep .el-input__inner {
                border: 1px solid #594FEE;
                border-radius: 15px;
            }
        }
        ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, ::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #1E33E3;
            border-color: #1E33E3;
        }
    }
</style>